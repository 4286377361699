<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div v-if="isLoadingTable" class="col-12">
        <div class="card">
          <div class="card-header">
            <el-skeleton :rows="4" animated />
          </div>
        </div>
      </div>
      <div v-if="showTable" class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Bulk Paket</h5>
            <p class="mb-0 text-sm">
              Daftar riwayat paket yang dibuat dari hasil file import (CSV).
            </p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click.prevent="GoToAddPackage"
                  >
                    + Tambah Paket
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      style="border-radius: 0px 5px 5px 0px"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>

              <el-table
                :data="queriedData"
                row-key="id"
                header-row-class-name="thead-light"
                @selection-change="selectionChange"
              >
                <template #empty>
                  <div class="flex items-center justify-center h-100%">
                    <el-empty />
                  </div>
                </template>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  v-bind="column"
                >
                </el-table-column>
                <el-table-column align="left" label="Kode Bulk">
                  <template #default="props">
                    <a
                      href="#"
                      @click.prevent="showDetail(props.$index, props.row)"
                      >{{ props.row.code }}<br />
                      <small class="text-secondary">
                        {{ props.row.user.email }}<br />
                        {{ props.row.display_created_at }}<br /> </small
                    ></a>
                  </template>
                </el-table-column>

                <el-table-column align="left" label="Progress">
                  <template #default="props">
                    <div class="text-success">
                      {{ props.row.total_success }}/{{ props.row.total }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="left" label="Status">
                  <template #default="props">
                    <span
                      v-if="props.row.status == 'completed'"
                      class="badge badge-pill badge-success"
                      >{{ props.row.status }}</span
                    >
                    <span
                      v-else-if="props.row.status == 'incompleted'"
                      class="badge badge-pill badge-warning"
                      >{{ props.row.status }}</span
                    >
                    <span
                      v-else-if="props.row.status == 'created'"
                      class="badge badge-pill badge-secondary"
                      >{{ props.row.status }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column align="left" label="Helper">
                  <template #default="props">
                    <small class="text-secondary">{{
                      props.row.helper_name
                    }}</small>
                  </template>
                </el-table-column>

                <el-table-column align="center" label="Action">
                  <template #default="props">
                    <button
                      v-if="props.row.status == 'created'"
                      class="btn btn-success btn-xs"
                      @click.prevent="handleProcess(props.$index, props.row)"
                    >
                      Go Process
                    </button>
                    <span v-else>
                      <a
                        class="mx-3"
                        href="#"
                        @click.prevent="showDetail(props.$index, props.row)"
                      >
                        <i class="fas fa-edit text-primary"></i>
                      </a>

                      <a
                        href="#"
                        @click.prevent="handleDelete(props.$index, props.row)"
                      >
                        <i class="fas fa-trash text-danger"></i>
                      </a>
                    </span>
                  </template>
                </el-table-column>
              </el-table>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <h5>Daftar Data Kiriman Paket</h5>
      <div class="table-responsive" :style="tableStyleBulkDetailList">
        <div v-if="showPackageDetailLoading">
          <el-skeleton :rows="4" animated />
        </div>
        <table
          v-if="showPackageDetail"
          style="font-size: 12px"
          class="table table-striped"
        >
          <thead>
            <tr>
              <th>Pengirim</th>
              <th>Penerima</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list in bulkDetailList" :key="list.id">
              <td>
                <span
                  v-if="list.extract_column_value.official_dropship_store != ''"
                >
                  {{ list.extract_column_value.official_dropship_store.name }}
                </span>
              </td>
              <td>
                <b>{{ list.extract_column_value.to_name }}</b
                ><br />
                {{ list.extract_column_value.to_phone_number }}<br />
                <span v-if="list.extract_column_value.subdistrict != ''"
                  >{{ list.extract_column_value.subdistrict }},</span
                >
                {{ list.extract_column_value.district }},
                {{ list.extract_column_value.province }} <br />[{{
                  list.extract_column_value.qty
                }}x]{{ list.extract_column_value.product_name }}
              </td>
              <td>
                <span v-if="list.send_package != ''">
                  <TrackParcelStatus
                    :status="list.send_package.status"
                    :message="list.error_message"
                  />
                </span>
                <span v-else class="badge badge-danger">incomplete</span>
              </td>
              <td class="text-center">
                <span v-if="list.send_package == ''">
                  <a class="mx-3" href="#" @click.prevent="showEditForm(list)">
                    <i class="fas fa-edit text-primary"></i>
                  </a>
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr style="margin-bottom: -20px" />
      <PrintAirWayBillBulkButton
        v-if="showPrintAirWayBillBulkButton"
        style="margin-top: 20px"
        :row-bulk-selected="rowBulkSelected"
        :data="dataSelectedBulkPackage"
      />
      &nbsp;
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click="modals.classic = false"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import {
  ElTable,
  ElTableColumn,
  ElEmpty,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
//import ArgonProgress from "@/components/ArgonProgress.vue";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import TrackParcelStatus from "@/components/TrackParcelStatus.vue";
import PrintAirWayBillBulkButton from "@/components/PrintAirWayBillBulkButton";

export default {
  name: "BulkPackages",
  components: {
    BasePagination,
    Auth,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElEmpty,
    ElSkeleton,
    //ArgonProgress,
    Modal,
    TrackParcelStatus,
    PrintAirWayBillBulkButton,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v1/admin/bulk_packages/read",
      urlprocess: "/api/v1/admin/bulk_packages/process",
      urlbulkdetail_list: "/api/v1/admin/bulk_packages/detail_list",
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["code","email","display_created_at","helper_name","status"],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      isLoadingTable: false,
      loadingTable: true,
      showTable: false,
      dataPackageSelected: [],

      bulkDetailList: [],
      bulkDetailList_total: 0,
      tableStyleBulkDetailList: "",

      indexActive: "",
      clickable: true,

      rowBulkSelected: [],
      showPackageDetailLoading: false,
      showPrintAirWayBillBulkButton: false,
      dataSelectedBulkPackage: [],
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },

    async getTables() {
      try {
        this.isLoadingTable = true;
        this.showTable = false;
        this.showPackageDetail = false;

        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.data.status == "200") {
          this.datas = response.data.data;
          this.tableData = this.datas;
          this.isLoadingTable = false;
          this.showTable = true;
        }
      } catch (error) {
        console.log(error);
        this.isLoadingTable = false;
        this.showTable = true;
        this.errorRedirect500(error);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    refreshTable() {
      this.getTables();
    },

    GoToAddPackage() {
      this.$router.push({ path: "/orders/send_packages" });
    },

    handleViewBulkPackageDetail(index, row) {
      console.log(index + " " + row);
    },
    async handleProcess(index, row) {
      try {
        this.loadingService();
        const response = await axios.get(this.urlprocess + "/" + row.code, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.loadingService().close();
        //console.log(index + " " + row);
        console.log(response.data);
        //this.getTables();
        Swal.fire({
          title: "God Job!",
          html: "Successfully create order.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        //this.getTables();
        location.reload();
      } catch (error) {
        this.loadingService().close();
        console.log(error);
      }
    },
    async showDetail(index, row) {
      try {
        this.tableStyleBulkDetailList = "";
        this.showPackageDetail = false;
        this.showPrintAirWayBillBulkButton = false;
        this.showPackageDetailLoading = true;
        this.modals.classic = true;
        const response = await axios.get(
          this.urlbulkdetail_list + "/" + row.code,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status == "200") {
          this.showPackageDetailLoading = false;
          this.showPackageDetail = true;
          this.showPrintAirWayBillBulkButton = true;
          this.bulkDetailList = response.data.data;
          this.rowBulkSelected = row;
          this.dataSelectedBulkPackage = this.bulkDetailList;
          this.bulkDetailList_total = this.bulkDetailList.length;
          if (this.bulkDetailList_total > 5) {
            this.tableStyleBulkDetailList = "height:500px;";
          } else {
            this.tableStyleBulkDetailList = "";
          }
        }
        this.showPackageDetailLoading = false;
      } catch (error) {
        this.showPackageDetailLoading = false;
        console.log(error);
      }
    },
    async showEditForm(data) {
      // if edit
      this.$router.push({
        name: "CreateSendPackages",
        params: {
          mode: "edit",
          is_admin: 1,
          send_package_id: null,
          bulk_list_package_id: data.bulk_list_package_id,
          data: JSON.stringify(data.extract_column_value),
          destination_address: "",
        },
      });
    },

    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
